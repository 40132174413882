import { template as template_300e53a9c4824279996ee140dcf54723 } from "@ember/template-compiler";
const FKLabel = template_300e53a9c4824279996ee140dcf54723(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
