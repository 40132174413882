import { template as template_bddf9a5eb0d34d829a280b5a667fc7e8 } from "@ember/template-compiler";
const FKText = template_bddf9a5eb0d34d829a280b5a667fc7e8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
