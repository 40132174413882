import { template as template_3dc4d55ad07843ad85d3b2ac536a5f54 } from "@ember/template-compiler";
const WelcomeHeader = template_3dc4d55ad07843ad85d3b2ac536a5f54(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
