import { template as template_564ebca913784340bd4f0854c91d2849 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_564ebca913784340bd4f0854c91d2849(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
